<template>
	<article class="assignments">
		<div class="site-wrapper rythm-v-l">
			<header class="page-header">
				<h1>Experts de garde</h1>
			</header>

			<section class="section--assignments-toolbar rythm-v">
				<div class="toolbar card flex-item-100"
						 data-toolbar="assignments">
					<select name="select-period"
									class="vgt-select"
									v-model="period"
									@change="getAssignments">
						<option :value="selectedPeriod.id"
										v-for="(selectedPeriod, index) in periods"
										:key="`assignments_period_${index}`">{{ selectedPeriod.name }}</option>
					</select>

					<select name="select-year"
									class="vgt-select"
									v-model="year"
									@change="getAssignments">
						<option :value="selectedYear"
										v-for="(selectedYear, index) in years"
										:key="`assignments_year_${index}`">{{ selectedYear }}</option>
					</select>

					<div class="flex-item--25 flex-row flex-nowrap">
						<multiselect name="search"
												 v-model="searchExpert"
												 label="name"
												 :options="multiSelectOptions"
												 :close-on-select="true"
												 placeholder="Filtrer sur un expert"
												 :multiple="false"
												 track-by="id"
												 :searchable="true"
												 :preselect-first="false"
												 :clear-on-select="false"
												 :preserve-search="false"
												 :internal-search="true"
												 selectLabel=""
												 selectedLabel="Sélectionné"
												 deselectLabel="Supprimer"
												 tag-placeholder="Ajouter">
						</multiselect>
					</div>

					<div class="flex-item flex-row flex-gap">

						<button v-if="isCoordinator"
										type="button"
										class="button--outline"
										@click="openModal('oncallExperts')">
							<svg fill="none"
									 height="24"
									 viewBox="0 0 24 24"
									 width="24"
									 class="icon"
									 xmlns="http://www.w3.org/2000/svg"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="m1.46447 15.4645c.93768-.9377 2.20945-1.4645 3.53553-1.4645h8c1.3261 0 2.5979.5268 3.5355 1.4645.9377.9376 1.4645 2.2094 1.4645 3.5355v2c0 .5523-.4477 1-1 1s-1-.4477-1-1v-2c0-.7956-.3161-1.5587-.8787-2.1213s-1.3257-.8787-2.1213-.8787h-8c-.79565 0-1.55871.3161-2.12132.8787s-.87868 1.3257-.87868 2.1213v2c0 .5523-.44772 1-1 1-.552285 0-1-.4477-1-1v-2c0-1.3261.526784-2.5979 1.46447-3.5355z"/><path d="m9 4c-1.65685 0-3 1.34315-3 3s1.34315 3 3 3c1.6569 0 3-1.34315 3-3s-1.3431-3-3-3zm-5 3c0-2.76142 2.23858-5 5-5 2.7614 0 5 2.23858 5 5s-2.2386 5-5 5c-2.76142 0-5-2.23858-5-5z"/><path d="m19.0318 14.88c.138-.5347.6835-.8563 1.2182-.7182 1.0727.2769 2.023.9023 2.7017 1.7779.6787.8755 1.0475 1.9517 1.0483 3.0596v2.0007c0 .5523-.4477 1-1 1s-1-.4477-1-1v-1.9993c0-.0001 0 .0001 0 0-.0006-.6646-.2218-1.3105-.629-1.8357-.4072-.5254-.9774-.9006-1.621-1.0668-.5347-.138-.8563-.6834-.7182-1.2182z"/><path d="m15.0312 2.88196c.137-.53502.6818-.8577 1.2168-.72071 1.0756.27538 2.0288.90088 2.7096 1.77789.6807.87701 1.0502 1.95565 1.0502 3.06586s-.3695 2.18885-1.0502 3.0659c-.6808.877-1.634 1.5025-2.7096 1.7779-.535.1369-1.0798-.1857-1.2168-.7208-.1369-.535.1857-1.0798.7208-1.21675.6453-.16522 1.2172-.54052 1.6257-1.06673.4084-.52621.6301-1.17339.6301-1.83952s-.2217-1.31331-.6301-1.83952c-.4085-.5262-.9804-.9015-1.6257-1.06673-.5351-.13699-.8577-.68176-.7208-1.21679z"/></g></svg>
							<span class="caption">Experts disponibles</span>
						</button>

						<button v-if="isCoordinator"
										type="button"
										class="button--outline"
										@click="openModal('assignments')">
							<svg xmlns="http://www.w3.org/2000/svg"
									 width="24"
									 height="24"
									 fill="none"
									 stroke="currentColor"
									 stroke-width="2"
									 stroke-linecap="round"
									 stroke-linejoin="round"
									 viewBox="0 0 24 24"
									 class="icon feather-refresh-cw"><path d="M11.1 2.043c-.518.054-1.084.156-1.64.296-2.432.613-4.626 2.234-5.977 4.413-.408.658-.905 1.759-.984 2.18-.099.525.255.934.924 1.07.515.105.811-.111 1.073-.783.439-1.122.966-1.951 1.762-2.773A7.6 7.6 0 0 1 8.386 4.87a8.011 8.011 0 0 1 7.774.307c.611.373.956.667 2.671 2.278l1.623 1.525-1.898.02-1.898.02-.199.102C16.144 9.284 16 9.56 16 10c0 .428.144.713.442.88l.178.1 3.18.025c1.749.015 3.233.027 3.298.029.164.005.566-.184.68-.319.233-.277.226-.152.213-3.775l-.011-3.32-.089-.167c-.202-.377-.753-.551-1.262-.398-.251.076-.414.201-.52.398-.088.165-.089.194-.109 2.192l-.02 2.024-1.7-1.598c-.935-.879-1.844-1.715-2.02-1.858a10.11 10.11 0 0 0-4.62-2.068c-.82-.137-1.809-.177-2.54-.102M.597 13.05c-.271.115-.401.231-.501.446l-.095.204v3.26c0 2.062.014 3.313.04 3.405.107.385.396.597.855.626.485.031.803-.109.984-.433l.1-.178.02-2.025.02-2.025 1.68 1.58c1.916 1.802 2.111 1.971 2.803 2.433 1.185.791 2.596 1.325 4.137 1.565.641.1 2.111.098 2.76-.003a13.606 13.606 0 0 0 2.08-.529 12.317 12.317 0 0 0 2.02-1.024 10.014 10.014 0 0 0 3.797-4.68c.207-.531.242-.688.205-.935-.052-.344-.433-.646-.925-.733-.274-.049-.475-.014-.652.113-.178.126-.261.26-.442.716-.471 1.181-1.055 2.063-1.929 2.908a7.397 7.397 0 0 1-2.083 1.459c-2.23 1.075-4.76 1.061-7.011-.038-.508-.248-1.135-.647-1.5-.956a127.91 127.91 0 0 1-1.817-1.686l-1.596-1.5L5.463 15c1.864-.019 1.921-.022 2.077-.106.335-.179.438-.39.438-.894 0-.316-.015-.408-.09-.55-.104-.198-.314-.348-.568-.407-.116-.027-1.316-.046-3.369-.053-2.961-.009-3.201-.005-3.354.06" fill-rule="evenodd" fill="currentColor" stroke="none"/></svg>
							<span class="caption">Générer les affectations</span>
						</button>
					</div>
				</div>
			</section>

			<section class="section--assignments">
				<div class="card shadow assignements-table-wrapper flex-item--100">
					<vue-good-table v-if="rows.length"
													ref="assignmentsTable"
													styleClass="vgt-table striped"
													:isLoading="loading"
													:group-options="groupOptions"
													:columns="columns"
													:rows="rows"
													:select-options="{
														enabled: isCoordinator,
														selectOnCheckboxOnly: true,
														selectionText: 'ligne(s) sélectionnée(s)',
														clearSelectionText: 'Aucun',
														resetAfter: false,
													}
														"
													:search-options="{
														enabled: true,
														externalQuery: searchExpert,
														searchFn: handleSearch,
														// trigger: 'enter'
													}

														">

						<!-- Actions liées à la sélection multiple -->
						<div slot="selected-row-actions">

							<!-- Expert primaire adultes -->
							<multiselect name="group-select-primary-expert-adults"
													 v-model="groupAssignments.expert_primary_adults"
													 label="name"
													 :options="groupMultiSelectOptions"
													 :close-on-select="true"
													 placeholder="Expert principal adultes"
													 :multiple="false"
													 track-by="id"
													 :searchable="true"
													 :preselect-first="false"
													 :clear-on-select="false"
													 :preserve-search="false"
													 :internal-search="true"
													 selectLabel=""
													 selectedLabel="Sélectionné"
													 deselectLabel="Supprimer"
													 tag-placeholder="Ajouter">
							</multiselect>

							<!-- Expert(s) secondaire(s) adultes -->
							<multiselect name="group-select-secondary-expert-adults"
													 v-model="groupAssignments.expert_secondary_adults"
													 label="name"
													 :options="groupMultiSelectOptions"
													 :close-on-select="false"
													 placeholder="Expert(s) secondaire(s) adultes"
													 :multiple="true"
													 track-by="id"
													 :searchable="true"
													 :preselect-first="false"
													 :clear-on-select="false"
													 :preserve-search="false"
													 :internal-search="true"
													 selectLabel=""
													 selectedLabel="Sélectionné"
													 deselectLabel="Supprimer"
													 tag-placeholder="Ajouter">
							</multiselect>

							<!-- Expert primaire enfants -->
							<multiselect name="group-select-primary-expert-children"
													 v-model="groupAssignments.expert_primary_children"
													 label="name"
													 :options="groupMultiSelectOptions"
													 :close-on-select="true"
													 placeholder="Expert principal enfants"
													 :multiple="false"
													 track-by="id"
													 :searchable="true"
													 :preselect-first="false"
													 :clear-on-select="false"
													 :preserve-search="false"
													 :internal-search="true"
													 selectLabel=""
													 selectedLabel="Sélectionné"
													 deselectLabel="Supprimer"
													 tag-placeholder="Ajouter">
							</multiselect>

							<!-- Expert(s) secondaire(s) enfants -->
							<multiselect name="group-select-secondary-expert-children"
													 v-model="groupAssignments.expert_secondary_children"
													 label="name"
													 :options="groupMultiSelectOptions"
													 :close-on-select="false"
													 placeholder="Expert(s) secondaire(s) enfants"
													 :multiple="true"
													 track-by="id">
							</multiselect>

							<button type="button"
											class="button--primary"
											@click="groupChangeExpert">
								<!-- Enregistrer</button> -->
								<span class="caption">Affecter</span>
								<svg class="icon"
										 role="img"
										 xmlns="http://www.w3.org/2000/svg"
										 width="20"
										 height="16"
										 fill="none"
										 viewBox="0 0 20 16">
																																																																																																																																																																																																																																																																																																																																																								                  <path
																																																																																																																																																																																																																																																																																																																																																								                    fill="currentColor"
																																																																																																																																																																																																																																																																																																																																																								                    fill-rule="evenodd"
																																																																																																																																																																																																																																																																																																																																																								                    d="M1.17157 10.7716C1.92172 10.0214 2.93913 9.6 4 9.6H9.6C10.6609 9.6 11.6783 10.0214 12.4284 10.7716 13.1786 11.5217 13.6 12.5391 13.6 13.6V15.2C13.6 15.6418 13.2418 16 12.8 16 12.3582 16 12 15.6418 12 15.2V13.6C12 12.9635 11.7471 12.353 11.2971 11.9029 10.847 11.4529 10.2365 11.2 9.6 11.2H4C3.36348 11.2 2.75303 11.4529 2.30294 11.9029 1.85286 12.353 1.6 12.9635 1.6 13.6V15.2C1.6 15.6418 1.24183 16 .8 16 .358172 16 0 15.6418 0 15.2V13.6C0 12.5391.421428 11.5217 1.17157 10.7716zM6.8 1.6C5.47452 1.6 4.4 2.67452 4.4 4 4.4 5.32548 5.47452 6.4 6.8 6.4 8.12548 6.4 9.2 5.32548 9.2 4 9.2 2.67452 8.12548 1.6 6.8 1.6zM2.8 4C2.8 1.79086 4.59086 0 6.8 0 9.00914 0 10.8 1.79086 10.8 4 10.8 6.20914 9.00914 8 6.8 8 4.59086 8 2.8 6.20914 2.8 4zM16 4C16.4418 4 16.8 4.35817 16.8 4.8V9.6C16.8 10.0418 16.4418 10.4 16 10.4 15.5582 10.4 15.2 10.0418 15.2 9.6V4.8C15.2 4.35817 15.5582 4 16 4z"
																																																																																																																																																																																																																																																																																																																																																								                    clip-rule="evenodd"
																																																																																																																																																																																																																																																																																																																																																								                  />
																																																																																																																																																																																																																																																																																																																																																								                  <path
																																																																																																																																																																																																																																																																																																																																																								                    fill="currentColor"
																																																																																																																																																																																																																																																																																																																																																								                    fill-rule="evenodd"
																																																																																																																																																																																																																																																																																																																																																								                    d="M12.8 7.2C12.8 6.75817 13.1582 6.4 13.6 6.4H18.4C18.8418 6.4 19.2 6.75817 19.2 7.2C19.2 7.64183 18.8418 8 18.4 8H13.6C13.1582 8 12.8 7.64183 12.8 7.2Z"
																																																																																																																																																																																																																																																																																																																																																								                    clip-rule="evenodd"
																																																																																																																																																																																																																																																																																																																																																								                  />
																																																																																																																																																																																																																																																																																																																																																								                </svg>
							</button>
						</div>

						<!-- Columns -->
						<template slot="table-column"
											slot-scope="props">
							<span v-if="props.column.field == 'actions'"></span>
							<span>{{ props.column.label }}</span>
						</template>

						<!-- Groups header -->
						<template slot="table-header-row"
											slot-scope="props">

							<div class="table-group-header flex-row flex-gap-s flex-center-v"
									 @click="handleRowClick(props.row)">
								<svg viewBox="0 0 15 15"
										 fill="none"
										 xmlns="http://www.w3.org/2000/svg"
										 width="15"
										 height="15"><path d="M3.5 0v5m8-5v5m-10-2.5h12a1 1 0 011 1v10a1 1 0 01-1 1h-12a1 1 0 01-1-1v-10a1 1 0 011-1z" stroke="currentColor"></path></svg>
								{{ props.row.label }}
							</div>
						</template>

						<!-- Rows -->
						<template slot="table-row"
											slot-scope="props">
							<div class="flex-row flex-center-v"
									 v-if="props.column.field == 'formated_assignment_date'">

								<div v-if="rowHasUnavailableExperts(assignments[props.row.assignment_date])"
										 class="flex-row flex-nowrap flex-center-v flex-gap-s"
										 title="Certains experts n’acceptent plus les gardes">
									<svg xmlns="http://www.w3.org/2000/svg"
											 width="1em"
											 height="1em"
											 viewBox="0 0 24 24"
											 fill="none"
											 stroke="#e95f0d"
											 stroke-width="2"
											 stroke-linecap="round"
											 stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
									<span class="caption">
										{{ props.row.formated_assignment_date }}
									</span>
								</div>

								<template v-else
													class="caption">
									{{ props.row.formated_assignment_date }}
								</template>
							</div>

							<!-- Expert primaire adultes -->
							<div class="flex-row flex-center-v"
									 v-if="props.column.field == 'expert_primary_adults'">

								<multiselect v-if="isCoordinator"
														 name="select-primary-expert-adults"
														 v-model="assignments[props.row.assignment_date].expert_primary_adults"
														 label="name"
														 :class="expertUnavailable([assignments[props.row.assignment_date].expert_primary_adults]) ? 'expert-not-oncall' : ''"
														 :options="expertsOncallOptions"
														 :close-on-select="true"
														 placeholder="Choisissez un expert"
														 :multiple="false"
														 track-by="id"
														 @input="changeExpert(props.row.assignment_date)"
														 :searchable="true"
														 :preselect-first="false"
														 :clear-on-select="false"
														 :preserve-search="false"
														 :internal-search="true"
														 selectLabel=""
														 selectedLabel="Sélectionné"
														 deselectLabel="Supprimer"
														 tag-placeholder="Ajouter">
								</multiselect>
								<div v-else-if="assignments[props.row.assignment_date] && assignments[props.row.assignment_date].expert_primary_adults && assignments[props.row.assignment_date].expert_primary_adults.name">
									{{ assignments[props.row.assignment_date].expert_primary_adults.name }}
								</div>
							</div>

							<!-- Expert(s) secondaire(s) adultes -->
							<div class="flex-row flex-center-v"
									 v-else-if="props.column.field == 'expert_secondary_adults'">

								<multiselect v-if="isCoordinator"
														 name="select-secondary-expert-adults"
														 v-model="assignments[props.row.assignment_date].expert_secondary_adults"
														 label="name"
														 :class="expertUnavailable(assignments[props.row.assignment_date].expert_secondary_adults) ? 'expert-not-oncall' : ''"
														 :options="expertsOncallOptions"
														 :close-on-select="false"
														 placeholder="Choisissez un ou plusieurs experts"
														 :multiple="true"
														 track-by="id"
														 @input="changeExpert(props.row.assignment_date)"
														 :searchable="true"
														 :preselect-first="false"
														 :clear-on-select="false"
														 :preserve-search="false"
														 :internal-search="true"
														 selectLabel=""
														 selectedLabel="Sélectionné"
														 deselectLabel="Supprimer"
														 tag-placeholder="Ajouter">
								</multiselect>
								<div v-else-if="assignments[props.row.assignment_date] && assignments[props.row.assignment_date].expert_secondary_adults">
									<div v-for="   expert    in    assignments[props.row.assignment_date].expert_secondary_adults   ">
										{{ expert.name }}
									</div>
								</div>
							</div>

							<!-- Expert primaire enfants -->
							<div class="flex-row flex-center-v"
									 v-else-if="props.column.field == 'expert_primary_children'">
								<multiselect v-if="isCoordinator"
														 name="select-primary-expert-children"
														 v-model="assignments[props.row.assignment_date].expert_primary_children"
														 label="name"
														 :class="expertUnavailable([assignments[props.row.assignment_date].expert_primary_children]) ? 'expert-not-oncall' : ''"
														 :options="expertsOncallOptions"
														 :close-on-select="true"
														 placeholder="Choisissez un expert"
														 :multiple="false"
														 track-by="id"
														 @input="changeExpert(props.row.assignment_date)"
														 :searchable="true"
														 :preselect-first="false"
														 :clear-on-select="false"
														 :preserve-search="false"
														 :internal-search="true"
														 selectLabel=""
														 selectedLabel="Sélectionné"
														 deselectLabel="Supprimer"
														 tag-placeholder="Ajouter">
								</multiselect>
								<div v-else-if="assignments[props.row.assignment_date] && assignments[props.row.assignment_date].expert_primary_children && assignments[props.row.assignment_date].expert_primary_children.name">
									{{ assignments[props.row.assignment_date].expert_primary_children.name }}
								</div>
							</div>

							<!-- Expert(s) secondaire(s) adultes -->
							<div class="flex-row flex-center-v"
									 v-else-if="props.column.field == 'expert_secondary_children'">
								<multiselect v-if="isCoordinator"
														 name="select-secondary-expert-children"
														 v-model="assignments[props.row.assignment_date].expert_secondary_children"
														 label="name"
														 :class="expertUnavailable(assignments[props.row.assignment_date].expert_secondary_children) ? 'expert-not-oncall' : ''"
														 :options="expertsOncallOptions"
														 :close-on-select="false"
														 placeholder="Choisissez un ou plusieurs experts"
														 :multiple="true"
														 track-by="id"
														 @input="changeExpert(props.row.assignment_date)"
														 :searchable="true"
														 :preselect-first="false"
														 :clear-on-select="false"
														 :preserve-search="false"
														 :internal-search="true"
														 selectLabel=""
														 selectedLabel="Sélectionné"
														 deselectLabel="Supprimer"
														 tag-placeholder="Ajouter">
								</multiselect>

								<div v-else-if="assignments[props.row.assignment_date] && assignments[props.row.assignment_date].expert_secondary_children">
									<div v-for="   expert    in    assignments[props.row.assignment_date].expert_secondary_children   ">
										{{ expert.name }}
									</div>
								</div>

							</div>
						</template>

						<template slot="loadingContent">
							<svg class="loader"
									 width="44"
									 height="44"
									 viewBox="0 0 44 44"
									 xmlns="http://www.w3.org/2000/svg"
									 stroke="currentColor"><g fill="none" fill-rule="evenodd" stroke-width="2"><circle cx="22" cy="22" r="1"><animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" /><animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" /></circle><circle cx="22" cy="22" r="1"><animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" /><animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" /></circle></g></svg>
						</template>
					</vue-good-table>
				</div>
			</section>
		</div>

		<oncall-experts-modal :open="modals.oncallExperts"
													:users="allExperts"
													@modalClose="modalClose('oncallExperts')"
													@input="toggleExpertOncall" />

		<assignments-modal :open="modals.assignments"
											 @modalClose="modalClose('assignments')"
											 @input="autoAssignments" />

	</article>
</template>

<style lang="scss">
@import "@/scss/assignments.scss";
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { HTTP } from "../http-common.js";

// Vue Good Table
import { VueGoodTable } from "vue-good-table";

//Multiselect
import Multiselect from "vue-multiselect";

// Oncall Experts modal
import OncallExpertsModal from "../components/OncallExpertsModal.vue";
import AssignmentsModal from "../components/AssignmentsModal.vue";

// Assignments component
export default {
	name: "Assignments",
	props: {},

	components: { VueGoodTable, Multiselect, OncallExpertsModal, AssignmentsModal },

	data: function () {
		return {
			loading: false,
			allExperts: [], // expert users
			experts: [], // experts accepting oncall
			assignments: [],
			groupAssignments: {
				expert_primary_adults: "",
				expert_primary_children: "",
				expert_secondary_adults: [],
				expert_secondary_children: [],
			},
			groupOptions: {
				enabled: true,
				collapsable: false,
				rowKey: 'month'
			},
			rows: [],
			columns: [
				{
					label: "Date",
					// field: "assignment_date",
					field: "formated_assignment_date",
					sortable: false,
					tdClass: "",
					width: "260px",
				},
				{
					label: "Expert principal adultes",
					field: "expert_primary_adults",
					sortable: false,
					tdClass: "",
				},
				{
					label: "Expert(s) secondaire(s) adultes",
					field: "expert_secondary_adults",
					sortable: false,
					tdClass: "",
				},
				{
					label: "Expert principal enfants",
					field: "expert_primary_children",
					sortable: false,
					tdClass: "",
				},
				{
					label: "Expert(s) secondaire(s) enfants",
					field: "expert_secondary_children",
					sortable: false,
					tdClass: "",
				},
			],
			year: null,
			period: null,
			periods: [
				{ id: 1, name: "De Janvier à Juin" },
				{ id: 2, name: "De Juillet à Décembre" },
			],
			searchExpert: "",
			modals: {
				oncallExperts: false,
				assignments: false,
			},
		};
	},

	computed: {

		// Current user data
		user() {
			return this.$store.state.userData;
		},

		// Is a regular user?
		user_is_regular: function () {
			let r = this.user.roles.reduce((acc, val, i, roles) => acc + parseInt(val));
			return r < 2;
		},

		expertsOncall() {
			// Only experts accepting on-call
			return this.allExperts.filter((u) => (parseInt(u.accept_oncall) === 1));
		},

		// multi select options for experts oncall only
		expertsOncallOptions() {
			if (!this.expertsOncall) {
				return [];
			}
			let options = this.expertsOncall.map((expert) => {
				return { name: `${expert.firstname} ${expert.lastname}`, id: expert.id };
			});
			return options;
		},

		multiSelectOptions() {
			if (!this.experts) {
				return [];
			}
			let options = this.experts.map((expert) => {
				return { name: `${expert.firstname} ${expert.lastname}`, id: expert.id };
			});
			return options;
		},

		groupMultiSelectOptions() {
			let options = [...this.multiSelectOptions];
			options.unshift({ name: "Aucun", id: -1 });
			return options;
		},

		isCoordinator() {
			if (this.user.roles.includes(3)) {
				return true;
			}
			return false;
		},

		years() {
			let now = new Date();
			const start = now.getFullYear() - 4;
			const finish = now.getFullYear() + 1;
			const years = Array.from({ length: finish - start + 1 }, (_, a) => a + start);
			return years;
			// return [now.getFullYear() - 1, now.getFullYear(), now.getFullYear() + 1];
		},
	},

	methods: {

		expandCurrentMonthRows() {
			// Expand current month
			const currentMonthRowIndex = this.rows.findIndex($ => $.month === new Date().getMonth());
			console.log("currentMonthRowIndex", currentMonthRowIndex);
			if (currentMonthRowIndex < 0) return false;
			this.rows[currentMonthRowIndex].vgtIsExpanded = true;
			this.rows[currentMonthRowIndex].expanded = true;
			// this.$set(this.rows[currentMonthRowIndex], 'vgtIsExpanded', true);
			console.log(currentMonthRowIndex, this.rows[currentMonthRowIndex]);
		},

		handleRowClick(row) {
			console.log(row);
			// row.vgtIsExpanded
		},

		// Find rows with unavailable experts
		rowHasUnavailableExperts(assignment) {
			return this.expertUnavailable([
				assignment.expert_primary_adults,
				...assignment.expert_secondary_adults,
				assignment.expert_primary_children,
				...assignment.expert_secondary_children
			]);
		},

		/**
		 * Return experts not oncall
		 *
		 * @param {array} experts 
		 * @return boolean
		 */
		expertUnavailable(experts) {
			if (!experts.length) return '';

			// check if an expert is unavailable
			const oncallExpertsIds = this.expertsOncall.map(e => parseInt(e.id));
			const unavailableExperts = [];

			experts.forEach(expert => {
				const expertId = parseInt(expert.id);
				if (!oncallExpertsIds.includes(expertId)) {
					unavailableExperts.push(expertId);
				}
			});

			return unavailableExperts.length > 0;
		},


		toggleLoader(status = -1) {
			if (status === -1) this.loading = !this.loading;
			else this.loading = status;
		},

		/**
		 * Confirm assignments generation
		 */
		autoAssignments: function (period) {
			if (!period || !period?.start || !period?.end) {
				return this.$toasted.global.appError({
					message: "<div>Choisissez une plage de date.</div>",
				});
			}

			this.$toasted.show(`<div class="message-wrapper"><strong>Générer les affectations&nbsp;?</strong></div>`, {
				containerClass: "toasted",
				theme: "primary",
				position: "center",
				duration: null,
				closeOnSwipe: false,
				action: [
					{
						text: "Annuler",
						onClick: (e, toastObject) => {
							toastObject.goAway(0);
							return;
						},
					},
					{
						text: "Confirmer",
						onClick: (e, toastObject) => {
							toastObject.goAway(0);
							this.processAutoAssignments(period);
						},
					},
				],
			});
		},

		/**
		 * Generate assignments
		 */
		processAutoAssignments(period) {
			this.toggleLoader(true);

			HTTP.post("/autoassignments", { start: period.start, end: period.end })
				.then(async (response) => {
					this.$toasted.global.appInfo({
						message: "Les affectations ont été générées.",
					});

					this.toggleLoader(false);
					this.modalClose('assignments');

					// reload
					await this.getExperts();
					this.getAssignments();
				})
				.catch(async (error) => {
					this.toggleLoader(false);
					console.error("autoassignments", error);

					let message = "<strong>Impossible de générer les affectations</strong>";
					if (error?.status === 403) message = "Vous ne pouvez pas générer des affectations dans le passé.";

					return this.$toasted.global.appError({
						message: message
					});

				});
		},

		/**
		 * Toggle expert accept oncall status
		 *
		 * @param boolean status 
		 * @param int userID 
		 * @return void
		 */
		async toggleExpertOncall(status, userID) {
			status = status ? 1 : 0;
			let udpate = await this.$store.dispatch("UPDATE_EXPERT_ONCALL", { userID, status });
		},

		/**
		 * Open modal
		 *
		 * @param {string} name Modal identifier
		 * @return void
		 */
		openModal(name) {
			this.modals[name] = true;
		},

		// close modal
		modalClose(name) {
			this.modals[name] = false;
			// reload lists
			if (name === 'assignments') this.getAssignments();
			if (name === 'oncallExperts') this.getExperts();
		},


		// Fetch experts
		async getExperts() {
			this.toggleLoader(true);
			let users = await this.$store.dispatch("GET_EXPERT_USERS");

			// Remove Coordinators
			this.allExperts = users.filter(
				(u) => ((u.roles.includes("3") && u.roles.includes("5")) || (!u.roles.includes("3") && u.roles.includes("5"))) // Expert (ext) + Coordinator OR Expert only
			);

			// Only experts accepting on-call
			// this.experts = this.allExperts.filter((u) => (parseInt(u.accept_oncall) === 1));

			this.experts = this.allExperts;
			this.toggleLoader(false);
		},

		// Fetch assignments
		getAssignments: function () {
			this.toggleLoader(true);
			let year = this.year;
			let period = this.period;

			this.$store
				.dispatch("GET_ASSIGNMENTS", { year, period })
				.then((response) => {
					this.toggleLoader(false);
					if (null !== response) {

						// Format entries
						Object.entries(response).forEach((element) => {
							if (!element[1] || !element[1].assignment_date) return;
							const details = element[1]

							let date = new Date(details.assignment_date);
							const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };

							details.formated_assignment_date = date.toLocaleDateString("fr-FR", options);
							details.expert_primary_adults = details?.expert_primary_adults ? this.addExpertDetails(details.expert_primary_adults) : "";
							details.expert_primary_children = details?.expert_primary_children ? this.addExpertDetails(details.expert_primary_children) : "";
							details.expert_secondary_adults = details?.expert_secondary_adults ? details.expert_secondary_adults.map(this.addExpertDetails) : [];
							details.expert_secondary_children = details?.expert_secondary_children ? details.expert_secondary_children.map(this.addExpertDetails) : [];
						});

						const formatedEntries = Object.values(response);

						// group by months
						const monthlyEntries = [];
						const groupedEntries = [];

						// group entries by month
						formatedEntries.forEach($ => {
							const assignmentDate = new Date(Date.parse($.assignment_date));
							const assignmentMonth = assignmentDate.getMonth();
							if (!monthlyEntries[assignmentMonth]) monthlyEntries[assignmentMonth] = [];
							monthlyEntries[assignmentMonth].push($);
						});

						const getMonthName = (monthNumber) => {
							const date = new Date();
							date.setMonth(monthNumber);
							return date.toLocaleString('fr-FR', {
								month: 'long',
							});
						};

						// format grouped rows
						monthlyEntries.forEach(($, i) => {
							groupedEntries.push({
								mode: 'span',
								label: `${getMonthName(i)} ${this.year}`,
								html: false,
								month: i,
								children: [...$],
								vgtIsExpanded: false, // i === new Date().getMonth()
							});
						});

						this.assignments = { ...response }; // raw assignments for reference
						// this.rows = formatedEntries; // not grouped by months
						this.rows = groupedEntries;

						// NOTE: not working…
						// this.expandCurrentMonthRows();

					} else {
						this.$toasted.global.appInfo({
							message: `Cette période n'est pas accessible`,
						});
					}
				})
				.catch((error) => {
					this.toggleLoader(false);
					console.error("GET_ASSIGNMENTS", error);
				});
		},

		/**
		 * Add expert details to assignments
		 *
		 * @param {any} id
		 * @return Object
		 */
		addExpertDetails: function (id) {
			if (id === null || id === "-1") return null;
			let expert = this.experts.find((expert) => parseInt(expert.id) == parseInt(id));
			if (expert == "undefined" || typeof expert === "undefined") return null;

			return {
				id: id,
				name: expert.firstname + " " + expert.lastname,
			};
		},

		/**
		 * Update assignment
		 *
		 * @param {any} assignment 
		 * @return void
		 */
		putAssignment: function (assignment) {
			this.$store.dispatch("PUT_ASSIGNMENT", assignment).then((success) => {
				if (!success) {
					this.$toasted.global.appError({
						message: "<strong>Impossible d'enregistrer l'affectation&nbsp;!",
					});
				}
			}).catch(async (error) => {
				this.toggleLoader(false);
				console.error("PUT_ASSIGNMENT", error);

				let message = "<strong>Impossible de modifier l'affectation&nbsp;!</strong>";
				if (error?.status === 403) message = "Vous ne pouvez pas modifier les affectations des années précédentes.";

				// reload
				await this.getExperts();
				this.getAssignments();

				this.$toasted.global.appError({
					message: message
				});

			});
		},

		// On change la selection d'un expert primaire
		changeExpert: function (date) {
			let data = this.assignments[date];
			this.putAssignment(data);
		},

		groupChangeExpert: function (e) {
			let selectedLines = this.$refs["assignmentsTable"].selectedRows;
			let groupAssignments = this.groupAssignments;

			// On retire les propriétés vides de l'objet sauf si l'utilisateur a précisé aucun
			for (let key in groupAssignments) {
				//Les options aucun ont une valeur de -1
				if ((key == "expert_primary_adults" || key == "expert_primary_children") && typeof groupAssignments[key] == "object" && groupAssignments[key].id == -1) {
					groupAssignments[key] = "";
				} else if (Object.values(groupAssignments[key]).find((el) => el.id == -1)) {
					groupAssignments[key] = [];
				} else if (!groupAssignments[key] || groupAssignments[key].length === 0) {
					delete groupAssignments[key];
				}
			}

			selectedLines.forEach((selectedLine) => {
				let date = selectedLine.assignment_date;
				this.assignments[date] = { ...this.assignments[date], ...groupAssignments };

				this.changeExpert(date);
				this.clearGroupAssignments();
			});
		},

		clearGroupAssignments: function () {
			this.groupAssignments = {
				expert_primary_adults: "",
				expert_primary_children: "",
				expert_secondary_adults: [],
				expert_secondary_children: [],
			};
		},

		// Selectionne les lignes qui correspondent à la recherche sur le nom d'un expert
		handleSearch: function (row, col, cellValue, searchExpert) {
			if (!searchExpert) {
				return cellValue;
			}

			for (const [key, value] of Object.entries(row)) {
				if ((key == "expert_primary_adults" || key == "expert_primary_children") && value?.id == searchExpert?.id) {
					return cellValue;
				} else if (key == "expert_secondary_adults" || key == "expert_secondary_children") {
					value.forEach((element) => {
						if (element?.id == searchExpert?.id) return cellValue;
					});
				}
			}
		},
	},

	/**
	 * Mounted
	 */
	async mounted() {
		// Not for regular users
		if (this.user_is_regular) {
			this.$router.push("/dashboard");
		}

		//Init data
		let now = new Date();

		this.year = now.getFullYear();

		if (now.getMonth() < 6) {
			this.period = 1;
		} else {
			this.period = 2;
		}

		// Fetch data
		await this.getExperts();
		this.getAssignments();
	},



};
</script>
