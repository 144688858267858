<template>
	<div class="modal-wrapper">
		<div class="modal-overlay"
				 @click="closeModal"></div>

		<section class="assignments-modal modal card shadow rythm-v"
						 role="dialog"
						 aria-labelledby="assignmentsExpertsModalTitle">
			<header class="flex-row flex-align-spaced flex-center-v flex-nowrap">
				<h2 id="assignmentsExpertsModalTitle">Générer les affectations</h2>
				<button class="button--icon"
								@click="closeModal">
					<svg role="img"
							 class="icon"
							 xmlns="http://www.w3.org/2000/svg"
							 width="14"
							 height="14"
							 fill="none"
							 viewBox="0 0 14 14"><title>Fermer</title><path fill="currentColor" fill-rule="evenodd" d="M13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893Z" clip-rule="evenodd" /><path fill="currentColor" fill-rule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z" clip-rule="evenodd"/></svg>
				</button>
			</header>

			<div class="flex flex-align-center rythm-v">

				<form id="assignmentsRangeForm"
							@submit.prevent="generateAssignments()"></form>

				<div class="optgroup flex-row flex-align-top flex-gap-s">
					<label>
						<input type="radio"
									 form="assignmentsRangeForm"
									 id="period1"
									 name="period1"
									 value="0"
									 v-model="periodIndex"
									 @change="setPeriod(0)" />
						Première période
					</label>

					<label>
						<input type="radio"
									 form="assignmentsRangeForm"
									 id="period2"
									 name="period2"
									 value="1"
									 v-model="periodIndex"
									 @change="setPeriod(1)" />
						Deuxième période
					</label>
				</div>

				<div class="flex-row flex-align-top flex-gap-s">
					<div class="input-group">
						<label for="assignmentsDateStart">Début de la période</label>
						<input type="date"
									 form="assignmentsRangeForm"
									 required
									 for="assignmentsDateStart"
									 v-model="selectedPeriod.start">
					</div>

					<div class="input-group"
							 style="margin-top:0">
						<label for="assignmentsDateEnd">Fin de la période</label>
						<input type="date"
									 form="assignmentsRangeForm"
									 required
									 for="assignmentsDateEnd"
									 v-model="selectedPeriod.end">
					</div>
				</div>

				<button type="submit"
								form="assignmentsRangeForm"
								class="button--outline">
					<svg xmlns="http://www.w3.org/2000/svg"
							 width="24"
							 height="24"
							 fill="none"
							 stroke="currentColor"
							 stroke-width="2"
							 stroke-linecap="round"
							 stroke-linejoin="round"
							 viewBox="0 0 24 24"
							 class="icon feather-refresh-cw"><path d="M11.1 2.043c-.518.054-1.084.156-1.64.296-2.432.613-4.626 2.234-5.977 4.413-.408.658-.905 1.759-.984 2.18-.099.525.255.934.924 1.07.515.105.811-.111 1.073-.783.439-1.122.966-1.951 1.762-2.773A7.6 7.6 0 0 1 8.386 4.87a8.011 8.011 0 0 1 7.774.307c.611.373.956.667 2.671 2.278l1.623 1.525-1.898.02-1.898.02-.199.102C16.144 9.284 16 9.56 16 10c0 .428.144.713.442.88l.178.1 3.18.025c1.749.015 3.233.027 3.298.029.164.005.566-.184.68-.319.233-.277.226-.152.213-3.775l-.011-3.32-.089-.167c-.202-.377-.753-.551-1.262-.398-.251.076-.414.201-.52.398-.088.165-.089.194-.109 2.192l-.02 2.024-1.7-1.598c-.935-.879-1.844-1.715-2.02-1.858a10.11 10.11 0 0 0-4.62-2.068c-.82-.137-1.809-.177-2.54-.102M.597 13.05c-.271.115-.401.231-.501.446l-.095.204v3.26c0 2.062.014 3.313.04 3.405.107.385.396.597.855.626.485.031.803-.109.984-.433l.1-.178.02-2.025.02-2.025 1.68 1.58c1.916 1.802 2.111 1.971 2.803 2.433 1.185.791 2.596 1.325 4.137 1.565.641.1 2.111.098 2.76-.003a13.606 13.606 0 0 0 2.08-.529 12.317 12.317 0 0 0 2.02-1.024 10.014 10.014 0 0 0 3.797-4.68c.207-.531.242-.688.205-.935-.052-.344-.433-.646-.925-.733-.274-.049-.475-.014-.652.113-.178.126-.261.26-.442.716-.471 1.181-1.055 2.063-1.929 2.908a7.397 7.397 0 0 1-2.083 1.459c-2.23 1.075-4.76 1.061-7.011-.038-.508-.248-1.135-.647-1.5-.956a127.91 127.91 0 0 1-1.817-1.686l-1.596-1.5L5.463 15c1.864-.019 1.921-.022 2.077-.106.335-.179.438-.39.438-.894 0-.316-.015-.408-.09-.55-.104-.198-.314-.348-.568-.407-.116-.027-1.316-.046-3.369-.053-2.961-.009-3.201-.005-3.354.06" fill-rule="evenodd" fill="currentColor" stroke="none"/></svg>
					<span class="caption">Générer les affectations</span>
				</button>
			</div>

		</section>
	</div>
</template>

<script>

export default {
	name: "AssignmentsModal",
	props: [],

	data: function () {
		return {
			// Assignments dates ranges
			dateRanges: [
				// period 1: january to june
				{
					start: '',
					end: '',
				},
				// period 2: july to december
				{
					start: '',
					end: '',
				},
			],
			selectedPeriod: {
				start: '',
				end: '',
			},
			periodIndex: ''
		};
	},

	methods: {

		generateAssignments() {
			this.$emit("input", this.selectedPeriod);
		},

		closeModal: function () {
			this.$emit("modalClose");
		},

		formatDateYMD(date) {
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			const formattedDate = `${year}-${month}-${day}`;
			return formattedDate;
		},

		setPeriod() {
			this.selectedPeriod = { ...this.dateRanges[this.periodIndex] };
		},
	},

	mounted() {
		// Assignments dates ranges
		const currentYear = new Date().getFullYear();

		// period 1: january to june
		const januaryFirst = new Date(currentYear, 0, 1);
		const juneLast = new Date(currentYear, 5, 30);
		this.dateRanges[0].start = this.formatDateYMD(januaryFirst);
		this.dateRanges[0].end = this.formatDateYMD(juneLast);

		// period 2: july to december
		const julyFirst = new Date(currentYear, 6, 1);
		const decemberLast = new Date(currentYear, 11, 31);
		this.dateRanges[1].start = this.formatDateYMD(julyFirst);
		this.dateRanges[1].end = this.formatDateYMD(decemberLast);
	}
};
</script>


<style lang="scss" scoped>
@import "~@/scss/02-tools/tools.mixins";
@import "~@/scss/06-components/components.modal";

section>*+* {
	margin-top: 1rem;
}

.assignments-modal {
	width: 40rem;
	max-width: 100%;
}
</style>